import "../assets/css/header.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../assets/svg/menu-svgrepo-com.svg";
import { ReactComponent as CloseIcon } from "../assets/svg/close.svg";
import { useState } from "react";

export default function Header() {
  const navigate = useNavigate();
  const [curtain, setCurtain] = useState();

  function handleNavClick(route) {
    navigate(route);
    setCurtain();
  }

  return (
    <header>
      <MenuIcon className="menu" onClick={() => setCurtain(1)} />
      <div id="logo" onClick={() => handleNavClick("")}>
        Podlahy Markiv
      </div>
      <nav className={curtain ? "show" : ""}>
        <CloseIcon className="menu" onClick={() => setCurtain()} />
        <div onClick={() => handleNavClick("")}>Hlavní</div>
        <div onClick={() => handleNavClick("services")}>Služby</div>
        {/* <div onClick={() => handleNavClick("gallery")}>Galerie</div> */}
        <div onClick={() => handleNavClick("about")}>O nás</div>
        <div onClick={() => handleNavClick("contact")}>Kontakt</div>
      </nav>

      {curtain && <div className="overlay" onClick={() => setCurtain()}></div>}
    </header>
  );
}
