import { useState, useEffect } from "react";
import "../assets/css/loading.css";

export default function Loading() {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <main className="loading">
      {showLoader && <div className="loader"></div>}
    </main>
  );
}
