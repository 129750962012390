import "../assets/css/footer.css";
import { ReactComponent as PhoneIcon } from "../assets/svg/simple-phone.svg";
import { ReactComponent as MailIcon } from "../assets/svg/mail.svg";
import { ReactComponent as LocationIcon } from "../assets/svg/location.svg";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <footer>
      <div>
        <div id="logo"> Podlahy Markiv</div>
        <nav>
          <div onClick={() => navigate(`/services#1`)}>Pokládka podlah</div>
          <div onClick={() => navigate(`/services#2`)}>
            Renovace podlah a schodišť
          </div>
          <div onClick={() => navigate(`/services#3`)}>Sportovní podlahy</div>
          <div onClick={() => navigate(`/services#4`)}>
            Dřevěné stěny, stropy a schodiště
          </div>
        </nav>
      </div>
      <div>
        <a href="tel:+420608842067">
          <div className="credential">
            <PhoneIcon /> +420 608 842 067
          </div>
        </a>
        <a href="mailto:v.markiv@email.cz">
          <div className="credential">
            <MailIcon /> v.markiv@email.cz
          </div>
        </a>
        <div className="credential">
          <LocationIcon /> Zubrnice, Týniště 33, 400 02
        </div>
      </div>
      <div className="after-footer">
        © {new Date().getFullYear()}, Podlahy Markiv
      </div>
    </footer>
  );
}
